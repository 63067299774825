import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Button, ButtonGroup, Container, Grid, TextField, Alert, Typography} from "@mui/material";

import './NewPlayer.css';

function NewPlayer() {

    const [mail, setMail] = useState('');
    const [name, setName] = useState('');
    const [gameId, setGameId] = useState('');
    const [carId, setCarId] = useState('');

    const navigate = useNavigate();
    const [gameStartButton, setGameStartButton] = useState(false);

    useEffect(() => {
        var path = window.location.hash.slice(1);
        var paths = path.split("?")

        if (paths.length > 1) {
            var params = paths[1].split("&");  // Obtener los parámetros de la URL (gameId=1604&...)

            params.forEach(param => {
                var pair = param.split("=");
                if (pair[0] === "gameId") {
                    var tempGameId = pair[1];
                    setGameId(tempGameId);
                }
            });

        } else {
            console.log('No se encontraron parámetros de consulta en la URL.');
        }

    }, []);

    function handleStartAnswer() {
        navigate("/answer?gameId=" + gameId + "&userId=" + mail);
    }

    async function onClickNewUserRest() {
        const url = "https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/user/new";
        const data = {
            mail,
            name,
            carId,
            gameId
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);
            setGameStartButton(true);
            return responseData;
        } catch (error) {
            console.error('Error posting data:', error);
            throw error;
        }
    }

    function onClickNewUser() {
        // Connect to the WebSocket server
        const socket = new WebSocket('wss://xvj6bpte5a.execute-api.us-east-1.amazonaws.com/production/');

        // Event listener for when the connection is opened
        socket.onopen = () => {
            console.log('WebSocket connection established');

            // Create the message object
            const message = {
                action: 'playerNew',
                message: {
                    name,
                    mail,
                    gameId,
                    carId,
                },
            };

            // Send the message as a JSON string
            socket.send(JSON.stringify(message));
            console.log('Message sent:', message);
        };

        socket.onmessage = (event) => {
            console.log(event.data);
            const messageByServer = JSON.parse(event.data);

            if (messageByServer['statusCode'] === 200) {
                alert(messageByServer['message']);
                setGameStartButton(true);
            }

        };

        // Event listener for when an error occurs
        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        // Event listener for when the connection is closed
        socket.onclose = () => {
            console.log('WebSocket connection closed');
        };

    }

    return (
        <Container maxWidth="l" sx={{padding: '2vh', height: '100vh', display: 'flex', flexDirection: 'column'}}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" component="div" sx={{flexGrow: 1}} className="centered-content-headline">
                        {
                            name === undefined || name === '' ?
                                "¡Bienvenido!" :
                                "¡Bienvenido, " + name.toUpperCase() + "!"
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}} className="centered-content-headline">
                        {
                            gameId === undefined || gameId === '' ?
                                "" : "Número de partida: " + gameId
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup variant="outlined" aria-label="Basic button group" fullWidth>
                        <Button
                            variant={carId === '1' ? 'contained' : 'outlined'}
                            onClick={() => setCarId('1')}>
                            AUTO 1
                        </Button>
                        <Button
                            variant={carId === '2' ? 'contained' : 'outlined'}
                            onClick={() => setCarId('2')}>
                            AUTO 2
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="new-player-name"
                        label="Nombre"
                        variant="outlined" fullWidth
                        onChange={(e) => setName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="new-player-mail"
                        label="Mail"
                        variant="outlined" fullWidth
                        onChange={(e) => setMail(e.target.value)}
                    />
                </Grid>
                {
                    gameId === undefined || gameId === '' ?
                        <Grid item xs={12}>
                            <TextField
                                id="game-id"
                                label="ID de juego"
                                variant="outlined" fullWidth
                                defaultValue={gameId}
                                onChange={(e) => setGameId(e.target.value)}
                            />
                        </Grid> :
                        <></>
                }
                <Grid item xs={12}>
                    <Alert severity="info">Al continuar, usted acepta los términos y condiciones del juego.</Alert>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            onClickNewUserRest()
                        }}
                        disabled={mail === '' || name === '' || gameId === '' || carId === ''}
                    >
                        CONTINUAR - PARTIDA {gameId}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            handleStartAnswer()
                        }}
                        disabled={!gameStartButton}
                    >
                        Comenzar a Jugar!
                    </Button>
                </Grid>
            </Grid>

        </Container>
    );
}

export default NewPlayer;
