import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './QuestionAccordion.css';
import CardQuestion from "../CardQuestion/CardQuestion";
import {Button, ButtonGroup, Grid} from "@mui/material";
import {useState} from "react";

function SingleAccordion(props) {

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon/>}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography>{props.full_question.question.toUpperCase()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <CardQuestion
                    connectionIds={props.connectionIds}
                    gameId={props.gameId}
                    full_question={props.full_question}
                />
            </AccordionDetails>
        </Accordion>
    );
}

async function getQuestions() {
    const url = `https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/admin/search/questions`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

function QuestionAccordion(props) {

    const [questions, setQuestions] = useState([]);

    function getQuestionsButtonOnpressed() {
        getQuestions()
            .then(data => {
                console.log('Respuesta dada por getQuestions:', data);
                setQuestions(data.questions);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function clearQuestions() {
        setQuestions([]);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ButtonGroup fullWidth size="large">
                    <Button
                        fullWidth
                        onClick={() => getQuestionsButtonOnpressed()}
                    >
                        Buscar preguntas
                    </Button>
                    <Button
                        fullWidth
                        onClick={() => clearQuestions()}
                    >
                        Limpiar
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
                {
                    questions === undefined || questions.length === 0 ? <></> :
                        questions.map((each, index) => (
                            <SingleAccordion
                                connectionIds={props.connectionIds}
                                gameId={props.gameId}
                                key={index}
                                full_question={each}
                            />
                        ))
                }
            </Grid>
        </Grid>
    )
}

export default QuestionAccordion;
