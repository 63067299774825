import {
    Button, ButtonGroup,
    Card, CardActions,
    CardContent,
    FormControl, FormControlLabel,
    FormLabel,
    Grid, Radio, RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import './CardQuestion.css';
import {useEffect, useState} from "react";

function CardQuestion(props) {

    const [question, setQuestion] = useState(props.full_question.question);
    const [question_id, setQuestionId] = useState(props.full_question.question_id);
    const [answer_1, setAnswer1] = useState(props.full_question.answer_1);
    const [answer_2, setAnswer2] = useState(props.full_question.answer_2);
    const [answer_3, setAnswer3] = useState(props.full_question.answer_3);
    const [answer_4, setAnswer4] = useState(props.full_question.answer_4);
    const [answer_right, setAnswerRight] = useState(props.full_question.answer_right);
    const [connectionIds, setConnectionIds] = useState(props.connectionIds);
    const [gameId, setGameId] = useState(props.gameId);

    useEffect(() => {
        setConnectionIds(props.connectionIds);
        setGameId(props.gameId);
    }, [props]); // Run the effect whenever `users` changes

    async function postNewQuestion() {
        const url = "https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/admin/save/question";
        const data = {
            question,
            answer_1,
            answer_2,
            answer_3,
            answer_4,
            answer_right
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);
            alert("Pregunta guardada correctamente")
            return responseData;
        } catch (error) {
            console.error('Error posting data:', error);
            throw error;
        }
    }

    async function postRightAnswer() {
        const url = "https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/admin/publish/answer";
        const data = {
            answer_right,
            connectionIds,
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);
            return responseData;
        } catch (error) {
            console.error('Error posting data:', error);
            throw error;
        }
    }

    async function postQuestion() {
        const url = "https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/admin/publish/question";
        const data = {
            question,
            question_id,
            answer_1,
            answer_2,
            answer_3,
            answer_4,
            answer_right,
            connectionIds,
            gameId
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);
            return responseData;
        } catch (error) {
            console.error('Error posting data:', error);
            throw error;
        }
    }

    function onClickPublish() {

        postQuestion()
            .then(data => {
                console.log('Datos de la respuesta:', data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Pregunta"}
                        variant="outlined"
                        defaultValue={props.full_question.question}
                        onChange={(e) => setQuestion(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Inserte respuesta 1"
                        variant="outlined"
                        defaultValue={props.full_question.answer_1}
                        onChange={(e) => setAnswer1(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Inserte respuesta 2"
                        variant="outlined"
                        defaultValue={props.full_question.answer_2}
                        onChange={(e) => setAnswer2(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Inserte respuesta 3"
                        variant="outlined"
                        defaultValue={props.full_question.answer_3}
                        onChange={(e) => setAnswer3(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Inserte respuesta 4"
                        variant="outlined"
                        defaultValue={answer_4}
                        onChange={(e) => setAnswer4(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="correct-answer-label">Respuesta correcta</FormLabel>
                        <RadioGroup
                            row
                            name="correct-answer"
                            defaultValue={answer_right}
                            onChange={(e) => setAnswerRight(e.target.value)}
                        >
                            <FormControlLabel value="1" control={<Radio/>} label="OPCIÓN 1"/>
                            <FormControlLabel value="2" control={<Radio/>} label="OPCIÓN 2"/>
                            <FormControlLabel value="3" control={<Radio/>} label="OPCIÓN 3"/>
                            <FormControlLabel value="4" control={<Radio/>} label="OPCIÓN 4"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup variant="outlined" aria-label="Basic button group" fullWidth>
                        <Button
                            size="large"
                            fullWidth
                            variant="outlined"
                            onClick={() => postNewQuestion()}
                        >
                            Guardar nueva
                        </Button>
                        <Button
                            size="large"
                            fullWidth
                            variant="outlined"
                            onClick={() => onClickPublish()}
                        >
                            Publicar
                        </Button>
                        <Button
                            size="large"
                            fullWidth
                            variant="outlined"
                            onClick={() => postRightAnswer()}
                        >
                            Publicar correcta
                        </Button>
                    </ButtonGroup>
                </Grid>

            </Grid>
        </>
    );
}

export default CardQuestion;
