import {Paper, Typography, Grid, ButtonGroup, Button, LinearProgress} from "@mui/material";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import EditIcon from '@mui/icons-material/Edit';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import './AnswerChecker.css';
import {useEffect, useState} from "react";

function AnswerChecker(props) {

    const max_time = 15;
    const [timer, setTimer] = useState(max_time);
    const [isActive, setIsActive] = useState(false)
    const [questionNumber, setQuestioNumber] = useState(1);
    const [usersAnswers, setUsersAnswers] = useState(undefined);

    const normalise = (value) => (value * 100) / max_time;

    useEffect(() => {
        let interval = null;

        if (isActive) {
            interval = setInterval(() => {
                if (timer > 0) {
                    setTimer(prevTimer => prevTimer - 1);
                } else {
                    clearInterval(interval);
                    setIsActive(false);
                    getAnswers();
                }
            }, 1000);
        } else if (!isActive && timer !== 0) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isActive, timer]);

    function startTimer(time) {
        setIsActive(true);
        setTimer(max_time);
    }

    function onClickNextQuestion() {
        setQuestioNumber(questionNumber + 1);
    }

    async function getAnswers() {
        const url = 'https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/admin/search/answers?gameId=' + props.gameId + '&questionNumber=' + questionNumber;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setUsersAnswers(data.users);
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            setUsersAnswers(undefined)
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ButtonGroup fullWidth size="large" aria-label="Large button group">
                        <Button
                            endIcon={<AvTimerIcon/>}
                            onClick={() => startTimer(max_time)}
                        >Comenzar timer</Button>
                        <Button
                            endIcon={<EditIcon/>}
                            onClick={() => getAnswers()}
                        >
                            Revisar respuestas
                        </Button>
                        <Button
                            endIcon={<QuestionMarkIcon/>}
                            onClick={() => onClickNextQuestion()}
                        >
                            Siguiente pregunta ({questionNumber + 1})
                        </Button>
                        <Button
                            endIcon={<RestartAltIcon/>}
                            onClick={() => setQuestioNumber(1)}
                        >
                            Reset
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress variant="determinate" value={normalise(timer)}/>
                </Grid>

                {
                    usersAnswers === undefined || usersAnswers === [] ?
                        <Grid item xs={12}>
                            <div>No hay respuestas para la partida <b>{props.gameId}</b> y la pregunta <b>{questionNumber}</b></div>
                        </Grid> :
                        usersAnswers.map((each, index) => (
                            <Grid item xs={4}>
                                <Paper elevation={3}
                                       style={{padding: '16px', backgroundColor: '#eceff1', color: 'black'}}
                                       key={index}>
                                    <Typography variant="h6" component="h5">
                                        <b>{each.user_id.toLowerCase()}</b>
                                    </Typography>
                                    <Typography component="p">
                                        Respuesta: <b>OPCIÓN {each.user_answer}</b>
                                    </Typography>
                                    <Typography component="p">
                                        {each.timestamp}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))
                }
            </Grid>

        </>
    )
        ;
}

export default AnswerChecker;
