import {useEffect, useState} from "react";

import {
    AppBar,
    Box, Button,
    Container, FormControl, CircularProgress,
    Grid, InputLabel, MenuItem, Select,
    Toolbar,
    Typography, TextField
} from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";

import './AdminQuestions.css';

function AdminQuestions() {

    useEffect(() => {
        loadFilesInS3();
    }, []);

    async function loadFilesInS3() {
        const url = 'https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/admin/search/files';

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setFiles(data.files);
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            setFiles([])
        }
    }

    async function onClickSubmit() {
        const url = "https://genai.triviaracer.kevincortes.cloud/askv2";
        const data = {
            "document_1": selectedFile,
            "question": userGuideline
        };

        setProgress(true);
        setGenaiResponse(undefined);
        setModifiedResponse(undefined);

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);
            console.log('Response data summary:', responseData.summary);
            setGenaiResponse(responseData.summary);
            setProgress(false);
            return responseData;
        } catch (error) {
            console.error('Error posting data:', error);
            throw error;
        }
    }

    async function postNewQuestion() {
        const url = "https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/admin/save/question";

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: modifiedResponse
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);
            alert("Pregunta guardada correctamente")
            return responseData;
        } catch (error) {
            console.error('Error posting data:', error);
            throw error;
        }
    }

    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');
    const [userGuideline, setUserGuideline] = useState('');

    const [progress, setProgress] = useState(false);
    const [genaiResponse, setGenaiResponse] = useState(undefined);
    const [modifiedResponse, setModifiedResponse] = useState(undefined);


    return (
        <>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            Administrador TriviaRacer
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <br/>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Archivos</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedFile}
                                label="Archivos"
                                onChange={(e) => setSelectedFile(e.target.value)}
                            >
                                {
                                    files === undefined || files === [] ?
                                        <div>No hay archivos</div> :
                                        files.map((each, index) => (
                                            <MenuItem key={index} value={each}>{each}</MenuItem>
                                        ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            label={"Ayudar al modelo"}
                            variant="outlined"
                            defaultValue={userGuideline}
                            onChange={(e) => setUserGuideline(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            onClick={() => onClickSubmit()}
                        >
                            Generar pregunta
                        </Button>
                    </Grid>
                    {
                        genaiResponse === undefined ? (
                                progress ?
                                    <Grid item xs={12}>
                                        <CircularProgress/>
                                    </Grid> :
                                    <></>
                            ) :
                            <>
                                <Grid item xs={12}>
                                    <CodeMirror
                                        value={genaiResponse}
                                        height="25vh"
                                        onChange={(e) => {
                                            setModifiedResponse(e)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        onClick={() => postNewQuestion()}
                                    >
                                        Guardar pregunta
                                    </Button>
                                </Grid>
                            </>
                    }

                </Grid>
            </Container>
        </>
    );
}

export default AdminQuestions;
