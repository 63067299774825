import {useRef, useState} from "react";

import {
    AppBar,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import CardPlayer from "../../components/CardPlayer/CardPlayer";
import './AdminGame.css';
import QuestionAccordion from "../../components/QuestionAccordion/QuestionAccordion";
import AnswerChecker from "../../components/AnswerChecker/AnswerChecker";
import CloseIcon from '@mui/icons-material/Close';

function AdminGame() {

    const [gameId, setGameId] = useState('');

    const [users, setUsers] = useState([]);

    async function getGameById(gameId) {
        const url = `https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/admin/search/gameId?gameId=${gameId}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

    function getUsersByGameId() {
        getGameById(gameId)
            .then(data => {
                console.log('Datos del juego:', data);
                setUsers([])
                setUsers(data.users);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    async function postEndGame(connectionIds) {
        const url = "https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/admin/publish/end";
        const data = {
            connectionIds,
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);
            return responseData;
        } catch (error) {
            console.error('Error posting data:', error);
            throw error;
        }
    }

    function onClickEndGame() {

        if (users === undefined || users === []) {
            console.log("Nada que terminar aun");
        } else {
            postEndGame(getConnectionIds(users))
                .then(data => {
                    console.log('Datos de la respuesta:', data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });

        }

    }

    function getConnectionIds(users) {
        var connectionIds_array = [];
        users.forEach(each => {

            if (each['connectionId'] !== undefined)
                connectionIds_array.push(each['connectionId'])
        });

        return connectionIds_array;
    }

    return (
        <>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            Administrador TriviaRacer
                        </Typography>
                        <Button
                            color="inherit"
                            onClick={() => {
                                onClickEndGame()
                            }}
                        >Finalizar juego</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <br/>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AnswerChecker
                            gameId={gameId}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="game-id"
                                    label="Game ID"
                                    variant="filled" fullWidth
                                    onChange={(e) => setGameId(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    endIcon={<PersonSearchIcon/>}
                                    onClick={() => {
                                        getUsersByGameId()
                                    }}
                                >
                                    Buscar jugadores
                                </Button>
                            </Grid>
                            {
                                users.length === 0 ? <></> :
                                    users.map((each, index) => (
                                        <Grid item xs={12} key={index}>
                                            <CardPlayer
                                                name={each['name']}
                                                mail={each['mail']}
                                                carId={each['carId']}
                                                connectionId={each['connectionId']}
                                            />
                                        </Grid>
                                    ))
                            }
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    endIcon={<CloseIcon/>}
                                    startIcon={<CloseIcon/>}
                                    color="error"
                                    disabled={users === []}
                                    onClick={() => {
                                        onClickEndGame()
                                    }}
                                >
                                    Finalizar
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>


                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {
                                    users.length === 0 ? <></> :
                                        <QuestionAccordion
                                            connectionIds={getConnectionIds(users)}
                                            gameId={gameId}
                                        />
                                }
                            </Grid>


                        </Grid>
                    </Grid>

                </Grid>
            </Container>
        </>
    );
}

export default AdminGame;
