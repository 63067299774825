import {Box, Button, Container, Grid, Typography} from "@mui/material";
import './GameOnTV.css';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

function GameOnTV(props) {

    const navigate = useNavigate();

    const [gameId, setGameId] = useState('');
    const [socketStatus, setSocketStatus] = useState(undefined);
    const [question, setQuestion] = useState('ESPACIO PARA PREGUNTA');
    const [answer_1, setAnswer1] = useState('OPCIÓN 1');
    const [answer_2, setAnswer2] = useState('OPCIÓN 2');
    const [answer_3, setAnswer3] = useState('OPCIÓN 3');
    const [answer_4, setAnswer4] = useState('OPCIÓN 4');
    const [answer_right, setAnswerRight] = useState(undefined);

    const [showAnswer, setShowAnswer] = useState(false);

    useEffect(() => {
        var path = window.location.hash.slice(1);
        var paths = path.split("?")

        if (paths.length > 1) {
            var params = paths[1].split("&");  // Obtener los parámetros de la URL (gameId=1604&...)

            params.forEach(param => {
                var pair = param.split("=");
                if (pair[0] === "gameId") {
                    var tempGameId = pair[1];
                    setGameId(tempGameId);
                }
            });

        } else {
            console.log('No se encontraron parámetros de consulta en la URL.');
        }

    }, []);

    function onClickStartGame() {
        // Connect to the WebSocket server
        const socket = new WebSocket('wss://xvj6bpte5a.execute-api.us-east-1.amazonaws.com/production/');

        setSocketStatus(socket);

        socket.onopen = () => {
            console.log('WebSocket connection established');

            // Create the message object
            const message = {
                action: 'gameTVPlayer',
                message: {
                    gameId
                },
            };

            // Send the message as a JSON string
            socket.send(JSON.stringify(message));
            console.log('Message sent:', message);
        }

        socket.onmessage = (event) => {
            console.log(event.data);
            const messageByServer = JSON.parse(event.data);

            if (messageByServer['statusCode'] === 200) {
                console.log(messageByServer['message']);
            }

            if (messageByServer['statusCode'] === 201) {
                setQuestion(messageByServer['message']['question']);
                setAnswer1(messageByServer['message']['answer_1']);
                setAnswer2(messageByServer['message']['answer_2']);
                setAnswer3(messageByServer['message']['answer_3']);
                setAnswer4(messageByServer['message']['answer_4']);
                setShowAnswer(false);
            }

            if (messageByServer['statusCode'] === 202) {
                var optionAnswerRight = parseInt(messageByServer['message']['answer_right'], 10);
                switch (optionAnswerRight) {
                    case 1 :
                        setAnswerRight(answer_1);
                        break;
                    case 2 :
                        setAnswerRight(answer_2);
                        break;
                    case 3 :
                        setAnswerRight(answer_3);
                        break;
                    case 4 :
                        setAnswerRight(answer_4);
                        break;
                }
                setShowAnswer(true);
            }

            if (messageByServer['statusCode'] === 204) {
                socket.close();
                navigate("/new/game");
            }

        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed');
        };

    }

    function onClickStop() {
        socketStatus.close();
        navigate("/new/game");
    }

    return (
        <Container maxWidth="l" sx={{padding: '2vh', height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <Grid container spacing={2} className="grid-full-height">
                <Grid item xs={12}>
                    <Box className="centered-content grey-background">
                        <Typography variant="h2" gutterBottom>
                            <b>{question.toUpperCase()}</b>
                        </Typography>
                    </Box>
                </Grid>
                {
                    showAnswer ?
                        <Grid item xs={12}>
                            <Box className="centered-content green-background">
                                <Typography variant="h4" gutterBottom>
                                    RESPUESTA CORRECTA: <b>{answer_right.toUpperCase()}</b>
                                </Typography>
                            </Box>
                        </Grid> :
                        <>
                            <Grid item xs={6}>
                                <Box className="centered-content red-background">
                                    <Typography variant="h4" gutterBottom>
                                        1- {answer_1.toUpperCase()}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className="centered-content blue-background">
                                    <Typography variant="h4" gutterBottom>
                                        2- {answer_2.toUpperCase()}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className="centered-content green-background">
                                    <Typography variant="h4" gutterBottom>
                                        3- {answer_3.toUpperCase()}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className="centered-content yellow-background">
                                    <Typography variant="h4" gutterBottom>
                                        4- {answer_4.toUpperCase()}
                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                }

            </Grid>
            <div className="space-between-grids"/>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={gameId === '' || socketStatus !== undefined}
                        onClick={() => onClickStartGame()}
                    >
                        INICIAR JUEGO {gameId}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={socketStatus === undefined}
                        onClick={() => onClickStop()}
                    >
                        FINALIZAR
                    </Button>
                </Grid>
            </Grid>


        </Container>
    );
}

export default GameOnTV;
