import {HashRouter as Router, Route, Routes} from 'react-router-dom';

import GameOnTV from "./pages/game/GameOnTV";
import NewGame from "./pages/new-game/NewGame";
import NewPlayer from "./pages/new-player/NewPlayer";
import AnswerGame from "./pages/answer-game/AnswerGame";

import './App.css';
import AdminGame from "./pages/admin-game/AdminGame";
import AdminQuestions from "./pages/admin-questions/AdminQuestions";

function App() {
  return (
      <Router>
          <Routes>
              <Route path="/" element={ <NewGame/> } />
              <Route path="/new/game" element={ <NewGame/> } />
              <Route path="/game" element={ <GameOnTV/> } />

              <Route path="/new/player" element={ <NewPlayer/> } />
              <Route path="/answer" element={ <AnswerGame/> } />

              <Route path="/admin/game" element={ <AdminGame/> } />
              <Route path="/admin/questions" element={ <AdminQuestions/> } />
          </Routes>
      </Router>
  );
}

export default App;
