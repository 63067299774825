import {Button, Container, Typography} from "@mui/material";
import QRCode from "react-qr-code";

import './NewGame.css';
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

function NewGame() {

    const navigate = useNavigate();
    const [gameId, setGameId] = useState('');

    function handleStartGame() {
        navigate("/game?gameId=" + gameId);
    }

    function generateGameId() {
        let now = new Date();
        let day = now.getDate();
        let month = now.getMonth() + 1;
        let hour = now.getHours();
        let minutes = now.getMinutes();

        // Asegurar que horas y segundos tengan dos dígitos
        let dayStr = day < 10 ? '0' + day : day.toString();
        let monthStr = month < 10 ? '0' + month : month.toString();
        let hoursStr = hour < 10 ? '0' + hour : hour.toString();
        let minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();

        // Formar la cadena en formato HHSS
        let generatedGameId = dayStr + hoursStr + minutesStr;

        return generatedGameId;
    }

    useEffect(() => {
        setGameId(generateGameId);
    }, []);

    return (
        <Container maxWidth="l" sx={{padding: '2vh', height: '100vh', display: 'flex', flexDirection: 'column'}}>

            <Typography variant="h4" gutterBottom className="centered-content-headline">
                ¡Escanea el código QR para iniciar una nueva partida!
            </Typography>

            <Typography variant="h1" gutterBottom className="centered-content-headline">
                CÓDIGO DE PARTIDA {gameId}
            </Typography>

            <QRCode
                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                value={"https://triviaracer.kevincortes.cloud/#/new/player?gameId=" + gameId}
                viewBox={`0 0 256 256`}
            />
            <div className="space-between-qr"/>
            <Button
                variant="contained"
                onClick={() => handleStartGame()}>
                Crear nueva partida
            </Button>

        </Container>
    );
}

export default NewGame;
