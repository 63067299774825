import {Box, Button, Container, Grid, Typography} from "@mui/material";
import './AnswerGame.css';
import {useEffect, useState} from "react";

function AnswerGame() {

    const [playerAnswer, setPlayerAnswer] = useState(undefined);
    const [questionNumber, setQuestionNumber] = useState(1);
    const [userHasSubmitted, setUserHasSubmitted] = useState(false);
    const [gameId, setGameId] = useState(undefined);
    const [userId, setUserId] = useState(undefined);

    useEffect(() => {
        const path = window.location.hash.slice(1);
        const paths = path.split("?");

        if (paths.length > 1) {
            const queryParams = new URLSearchParams(paths[1]);
            const tempGameId = queryParams.get('gameId');
            const tempUserId = queryParams.get('userId');

            if (tempGameId) {
                console.log(tempGameId)
                setGameId(tempGameId);
            } else {
                console.log('No se encontró el parámetro "gameId" en la URL.');
            }

            if (tempUserId) {
                console.log(tempUserId);
                setUserId(tempUserId);
            } else {
                console.log('No se encontró el parámetro "userId" en la URL.');
            }
        } else {
            console.log('No se encontraron parámetros de consulta en la URL.');
        }
    }, []);

    async function onClickSubmitAnswer() {
        const url = "https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/user/answer";
        const data = {
            "game_id": gameId,
            "user_id": userId,
            "question_game_number": questionNumber,
            "user_answer": playerAnswer
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);
            setUserHasSubmitted(true);
            return responseData;
        } catch (error) {
            console.error('Error posting data:', error);
            throw error;
        }
    }

    function onClickNextQuestion() {
        setQuestionNumber(questionNumber + 1);
        setPlayerAnswer(undefined);
        setUserHasSubmitted(false);
    }

    return (
        <Container maxWidth="l" sx={{padding: '2vh', height: '90vh', display: 'flex', flexDirection: 'column'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box className="centered-content">
                        <div>
                            <p>PREGUNTA NÚMERO {questionNumber}</p>
                        </div>
                    </Box>
                </Grid>
            </Grid>
            <div className="space-between-grids"/>
            <Grid container spacing={2} className="grid-full-height">
                <Grid item xs={12}>
                    <Box className="centered-content red-background" onClick={() => {
                        setPlayerAnswer("1")
                    }}>
                        <p>OPCIÓN 1</p>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className="centered-content blue-background" onClick={() => {
                        setPlayerAnswer("2")
                    }}>
                        <p>OPCIÓN 2</p>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className="centered-content green-background" onClick={() => {
                        setPlayerAnswer("3")
                    }}>
                        <p>OPCIÓN 3</p>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className="centered-content yellow-background" onClick={() => {
                        setPlayerAnswer("4")
                    }}>
                        <p>OPCIÓN 4</p>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {
                            playerAnswer === undefined ?
                                <b>No seleccionaste aún una respuesta</b> :
                                <b>Seleccionaste la opción {playerAnswer}</b>
                        }
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={userHasSubmitted || playerAnswer === undefined}
                        size="large"
                        onClick={() => {
                            onClickSubmitAnswer()
                        }}
                    >
                        Subir respuesta
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={!userHasSubmitted || questionNumber >= 4}
                        size="large"
                        onClick={() => {
                            onClickNextQuestion();
                        }}
                    >
                        Siguiente pregunta
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AnswerGame;
