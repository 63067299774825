import {Button, Card, CardContent, Paper, Typography} from "@mui/material";
import './CardPlayer.css';
import MinorCrashIcon from "@mui/icons-material/MinorCrash";

function CardPlayer(props) {

    async function postMoveCar(carId) {
        const url = "https://u5kw0pqki9.execute-api.us-east-1.amazonaws.com/production/user/move";
        const data = {
            "carId": carId
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response data:', responseData);
            return responseData;
        } catch (error) {
            console.error('Error posting data:', error);
            throw error;
        }
    }

    return (
        <>
            <Paper elevation={3}
                   style={{padding: '16px', backgroundColor: '#e3f2fd', color: 'black'}}
                   key={props.mail}>
                <Typography variant="h6">
                    <b>{props.name.toUpperCase()}</b>
                </Typography>

                <Typography component="p">
                    <p>ID (Mail): <b>{props.mail.toLowerCase()}</b></p>
                </Typography>

                {
                    !props.mail.startsWith("TV") ?
                        <></> :
                        <p>ConnectionID: <b>{props.connectionId}</b></p>
                }

                {
                    !props.mail.startsWith("TV") ?
                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<MinorCrashIcon/>}
                            endIcon={<MinorCrashIcon/>}
                            onClick={() => postMoveCar(props.carId)}
                        >Avanzar auto {props.carId}</Button> : <></>
                }
            </Paper>
        </>
    );
}

export default CardPlayer;
